.loading {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  color: #000;
  font-size: 0;
  animation: ball-spin-fade-rotate 6s infinite linear;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    float: none;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;
    background-color: currentcolor;
    border: 0 solid currentcolor;
    border-radius: 100%;
    animation: ball-spin-fade 1s infinite linear;

    &:first-of-type {
      top: 5%;
      left: 50%;
      animation-delay: -1.125s;
    }

    &:nth-child(2) {
      top: 18.18%;
      left: 81.82%;
      animation-delay: -1.25s;
    }

    &:nth-child(3) {
      top: 50%;
      left: 95%;
      animation-delay: -1.375s;
    }

    &:nth-child(4) {
      top: 81.82%;
      left: 81.82%;
      animation-delay: -1.5s;
    }

    &:nth-child(5) {
      top: 95%;
      left: 50%;
      animation-delay: -1.625s;
    }

    &:nth-child(6) {
      top: 81.82%;
      left: 18.2%;
      animation-delay: -1.75s;
    }

    &:nth-child(7) {
      top: 50%;
      left: 5%;
      animation-delay: -1.875s;
    }

    &:last-of-type {
      top: 18.17%;
      left: 18.18%;
      animation-delay: -2s;
    }
  }
}

@keyframes ball-spin-fade {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.25;
  }
}

@keyframes ball-spin-fade-rotate {
  to {
    transform: rotate(360deg);
  }
}
