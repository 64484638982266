* {
  box-sizing: border-box;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}

.clearfix::after {
  clear: both;
}

a[disabled] {
  pointer-events: none;
}

[class*='prevent-scroll'] {
  height: 100vh;
  overflow: hidden;
}

[hidden] {
  display: none !important;
}

.right {
  float: right;
}

.left {
  float: left;
}
