@font-face {
  font-family: iconfont; /* Project id 3900677 */
  src: url('iconfont.woff2?t=1676600606309') format('woff2'),
    url('iconfont.woff?t=1676600606309') format('woff'),
    url('iconfont.ttf?t=1676600606309') format('truetype');
}

.iconfont {
  font-size: 16px;
  font-family: iconfont !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bangzhu::before {
  content: '\100fb';
}

.icon-guanbi::before {
  content: '\100fc';
}

.icon-dingwei::before {
  content: '\100fd';
}

.icon-caidan::before {
  content: '\100fe';
}
